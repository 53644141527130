import {
  ChartDataset,
  ScatterDataPoint,
  ScriptableLineSegmentContext,
} from 'chart.js';
import type {
  AccountDocument,
  AccountsById,
  BudgetDocument,
  BudgetType,
  BudgetsById,
  DataPoint,
  FillDay,
  Projection,
  ProjectionWithAvailable,
  TransactionDocument,
  TransactionDocumentWithId,
  BudgetViewDocument,
} from '@og-shared/types';
import { addHours } from 'date-fns';
import {
  isBankTransaction,
  sortTransactions,
  getTodayString,
  calcFrequencyFromBudget,
  shouldIncludeBudgetBasedOnStartAndEndDates,
  getNextDateAfterMinDate,
  getDateAtMidnight,
  getNextDate,
  formatDateFromString,
  dictionaryToArray,
  isPresent,
  keys,
  budgetNotTracked,
  IGNORE_BUDGET_ID,
  exhaustiveCheck,
} from '@og-shared/utils';

import { budgetTypes } from '../services/static-data';
import {
  getColor,
  getIonColorRgba,
  getTextColor,
  primaryColor,
} from './colors';
import { translate } from './translate';
import { getNextDateString } from './date-utils';
import {
  calcTotalAvailable,
  getAccountDisplayNumber,
  getAccountName,
} from './account-utils';
import { getGroupByKeyFromDate } from './past-transactions';
import { isFuture } from './utils';

type GroupBy = 'year' | 'month' | 'week' | 'day';

function getProjectedTransactions(
  budgets_by_id: BudgetsById,
  fill_day: FillDay,
  start: string,
  end: string
) {
  const budgetsById = JSON.parse(JSON.stringify(budgets_by_id)) as BudgetsById;
  let projections: Projection[] = [];
  keys(budgetsById).map(budget_id => {
    if (budgetNotTracked(budget_id)) return;
    const budget = budgetsById[budget_id];
    if (!budget) return;
    let dateString = start;
    const todayString = getTodayString();
    if (shouldIncludeToday(budget) && end > todayString) {
      const todayProjection: Projection = {
        date: getTodayString(),
        amount: 0,
        icon: 'flash',
        color: 16,
        label: translate('TODAY'),
        projection_type: 'today',
        budget_id,
        budget_type: budget.type,
      };
      projections.push(todayProjection);
    }
    // 1. set any missing next transaction dates
    if (
      !budget.next_transaction_date &&
      budget.budget_id !== 'INCOME' &&
      budget.per_year !== 0
    ) {
      budget.next_transaction_date = getNextDate(
        dateString,
        budget.per_year,
        budget.fill_days
      );
      // console.log('set missing transaction date', budget);
    }
    // 2. set any past due bill/income to happen today
    let nextTransactionDate: string | null =
      budget.next_transaction_date ?? null;
    if (
      isPresent(nextTransactionDate) &&
      getTodayString() > nextTransactionDate &&
      budget.budget_id !== 'INCOME'
      // && (budget.type === 'INCOME' || budget.type === 'BILL')
    ) {
      // if budgets are already due
      // add to today's projection
      // and increase next due date
      while (nextTransactionDate < getTodayString()) {
        const amount =
          budget.type === 'INCOME' ? budget.budget : budget.budget * -1;
        const item: Projection = {
          date: getTodayString(), //@todo - should this be start date? when would I ever get projected transactions that aren't from today?
          amount,
          label: translate('BUDGET_PAST_DUE', {
            name: budget.name,
            nextDate: formatDateFromString(nextTransactionDate),
          }),
          projection_type: 'future',
          budget_type: budget.type,
          account_id: budget.account_id,
          color: budget.color,
          icon: budget.icon,
          budget_id,
        };
        projections.push(item);
        nextTransactionDate =
          budget.per_year !== 0
            ? getNextDateAfterMinDate({
                startDateString: nextTransactionDate,
                budget: {
                  per_year: budget.per_year,
                  fill_days: budget.fill_days,
                },
                minDateString: nextTransactionDate,
              })
            : null;
        budget.next_transaction_date = nextTransactionDate;
        if (nextTransactionDate === null) break;
        // console.log('set any past due budget to happen today', budget);
      }
    }

    // 3-4. loop day by day to project upcoming transactions and fills
    // keep savings in projections
    while (dateString <= end) {
      // 3. add weekly fill projections for budgets
      if (shouldIncludeBudgetWeeklyFill(dateString, fill_day, budget)) {
        const item = getWeeklyFillFromBudget(budget, dateString);
        if (item.amount !== 0) {
          budget.available = budget.available + item.amount;
          projections.push(item);
        }
      }
      // 4. add projected budget transactions

      if (shouldIncludeProjection(dateString, budget)) {
        const item = getProjectionFromBudget(budget, dateString);
        budget.available = budget.available + item.amount;
        projections.push(item);
        budget.next_transaction_date =
          budget.per_year !== 0
            ? getNextDateAfterMinDate({
                startDateString: budget.next_transaction_date!,
                budget: {
                  per_year: budget.per_year,
                  fill_days: budget.fill_days,
                },
                minDateString: dateString,
              })
            : null;
      }
      const nextDateString = getNextDateString(dateString);
      if (dateString === nextDateString) {
        // prevents infinite loops if our add date code is bad :(
        console.error('failed to add one day', dateString, nextDateString);
        break;
      }
      dateString = nextDateString;
    }
    // 5 add end
    const endBalance: Projection = {
      date: end,
      amount: 0,
      icon: 'flash',
      color: 16,
      label: translate('END'),
      projection_type: 'end',
      budget_type: budget.type,
      budget_id,
    };
    projections.push(endBalance);
  });
  // 6. add weekly fill projections - coming out of income - based on fill projections above
  const incomeProjections = addIncomeWeeklyFillProjections(projections);
  projections = [...projections, ...incomeProjections];
  // 7. sort by date
  projections = projections.sort((a, b) => {
    if (a.date < b.date) {
      return -1;
    } else if (a.date > b.date) {
      return 1;
    } else if (a.projection_type === 'today') {
      return -1;
      // do today, then fill first
    } else if (a.projection_type === 'future-fill') {
      // if same date - do fills first
      return -1;
    } else {
      return 0;
    }
  });
  // fills need to be included,
  // all income sources need to be included
  // by the time they get here they are combined and sorted
  //
  // Now we can sort through and project available budget balances
  return projections;
}

function shouldIncludeProjection(dateString: string, budget: BudgetDocument) {
  return (
    dateString === budget.next_transaction_date &&
    shouldIncludeBudgetBasedOnStartAndEndDates(budget, dateString) &&
    (budget.budget !== 0 || // don't include zero budgets unless they are transfers
      (budget.budget === 0 &&
        budget.type === 'BILL' &&
        budget.subtype === 'TRANSFER'))
  );
}

export function getProjectedTransactionsByBudgetId(
  budgets_by_id: BudgetsById,
  past: Projection[],
  fill_day: FillDay,
  start: string,
  end: string
) {
  const availableByBudgetId: { [budget_id: string]: number } = {};
  keys(budgets_by_id).map(budget_id => {
    availableByBudgetId[budget_id] = budgets_by_id[budget_id]?.available ?? 0;
  });
  const projections =
    end > getTodayString()
      ? getProjectedTransactions(budgets_by_id, fill_day, start, end)
      : [];
  return getProjectionsByBudgetId(
    availableByBudgetId,
    budgets_by_id,
    past,
    projections
  );
}

function shouldIncludeBudgetWeeklyFill(
  dateString: string,
  fillDay: FillDay,
  budget: BudgetDocument
) {
  const day = getDateAtMidnight(dateString).getDay();
  const today = getTodayString();
  if (dateString === today) return false;
  // don't add fill day for today - it already happened
  if (day == fillDay) {
    if (budget.type !== 'INCOME') {
      if (shouldIncludeBudgetBasedOnStartAndEndDates(budget, dateString)) {
        if (budget.parent_budget_id === 'INCOME') {
          // don't include fills, all their cash comes from INCOME
          return false;
        }
        return true;
      }
      return false;
    }
  }
  return false;
}

function shouldIncludeToday(budget: BudgetDocument) {
  return budget.budget_id === 'INCOME' || budget.type !== 'INCOME';
}

function getProjectionsByBudgetId(
  availableByBudgetId: { [budget_id: string]: number },
  budgets_by_id: BudgetsById,
  past: Projection[],
  projections: Projection[]
) {
  const pastAvailableByBudgetId = { ...availableByBudgetId };
  const projectionsByBudgetId: {
    [budget_id: string]: ProjectionWithAvailable[];
  } = {};

  projections.map(projection => {
    const budget_id = getProjectionBudgetId(
      projection.budget_id,
      budgets_by_id
    );
    if (!budget_id) return;
    if (!projectionsByBudgetId[budget_id]) {
      projectionsByBudgetId[budget_id] = [];
    }
    const available =
      (availableByBudgetId[budget_id] ?? 0) + projection.amount || 0;
    availableByBudgetId[budget_id] = available;

    projectionsByBudgetId[budget_id].push({ ...projection, available });
  });

  keys(pastAvailableByBudgetId).map(originalBudgetId => {
    const budget_id = getProjectionBudgetId(originalBudgetId, budgets_by_id);
    // if original is 'income' include all income types...
    // if original is not income - include only income?
    const pastForBudget = past.filter(p =>
      originalBudgetId === 'INCOME'
        ? p.budget_id === 'INCOME' || p.budget_id !== originalBudgetId
        : p.budget_id === budget_id
    );
    const pastWithAvailable = convertPastProjectionsToWithAvailable(
      pastForBudget,
      pastAvailableByBudgetId[budget_id as string] // FIXME
    );
    projectionsByBudgetId[budget_id as string] = [
      // FIXME
      ...pastWithAvailable,
      ...(projectionsByBudgetId[budget_id as string] ?? []),
    ];
  });

  return projectionsByBudgetId;
}

function getProjectionBudgetId(
  originalBudgetId: string,
  budgets_by_id: BudgetsById
) {
  // only used for income budgets - to project income
  let budget_id = originalBudgetId;

  const budget = budgets_by_id[budget_id];
  if (!budget) return null;

  if (typeof budget.parent_budget_id === 'string') {
    const parentBudgetId = budgets_by_id[budget.parent_budget_id]?.budget_id;
    budget_id = parentBudgetId ?? originalBudgetId;
  }
  if (budget.type === 'INCOME') {
    budget_id = 'INCOME';
  }

  return budget_id;
}

function getProjectedTransactionsByAccountId(
  accounts_by_id: AccountsById,
  past: Projection[],
  future: Projection[],
  end: string
) {
  const accountsById: AccountsById = JSON.parse(JSON.stringify(accounts_by_id));
  const projectionsByAccountId: {
    [account_id: string]: ProjectionWithAvailable[];
  } = {};

  keys(accountsById).map(account_id => {
    // add today's balance for each account;
    // const account = accountsById[account_id];
    const available = getAccountDisplayNumber(accountsById[account_id]);
    const pastForAccount = past.filter(p => p.account_id === account_id);
    const pastWithAvailable = convertPastProjectionsToWithAvailable(
      pastForAccount,
      available
    );
    projectionsByAccountId[account_id] = pastWithAvailable;
    if (end > getTodayString()) {
      const todaysBalance: ProjectionWithAvailable = {
        date: getTodayString(),
        amount: 0,
        icon: 'flash',
        color: 16,
        label: translate('TODAY'),
        budget_type: null as any, // FIXME
        projection_type: 'today',
        budget_id: null as any, // FIXME
        available,
      };
      projectionsByAccountId[account_id].push(todaysBalance);
    }
  });

  future.map(projection => {
    const account_id = projection.account_id;
    if (!account_id) return;
    if (!projectionsByAccountId[account_id]) {
      projectionsByAccountId[account_id] = [];
    }
    const accountProjections = getAccountProjectionsWithAvailableFromProjection(
      accountsById,
      projection
    );
    accountProjections.map(a => {
      accountsById[a.account.account_id] = a.account;
      if (!projectionsByAccountId[a.account.account_id]) {
        projectionsByAccountId[a.account.account_id] = [];
      }
      projectionsByAccountId[a.account.account_id].push(
        a.projectionWithAvailable
      );
    });
  });
  keys(accountsById).map(account_id => {
    const projectionsWithAvailable = projectionsByAccountId[account_id];
    let available = getAccountDisplayNumber(accountsById[account_id]);
    const lastIndex = projectionsWithAvailable.length - 1;
    if (projectionsWithAvailable[lastIndex]?.available) {
      // set last datapoint on graph as the same available as the last projection
      available = projectionsWithAvailable[lastIndex].available;
    }
    const endBalance: ProjectionWithAvailable = {
      date: end,
      amount: 0,
      icon: 'flash',
      color: 16,
      label: translate('END'),
      projection_type: 'end',
      budget_type: null as any, // FIXME
      budget_id: null as any, // FIXME
      available,
    };
    projectionsByAccountId[account_id] = [
      ...projectionsWithAvailable,
      endBalance,
    ];
  });
  return projectionsByAccountId;
}

function getAccountProjectionsWithAvailableFromProjection(
  accountsById: AccountsById,
  projection: Projection
) {
  const accountProjections: {
    projectionWithAvailable: ProjectionWithAvailable;
    account: AccountDocument;
  }[] = [];
  // @todo here we could divide up interest and principle based on account type
  // but only if it's a deposit into an account that is a loan
  let fromAccount = accountsById[`${projection.account_id}`];
  let toAccount = accountsById[`${projection.to_account_id}`];

  if (
    projection.projection_type === 'future-transfer' &&
    toAccount &&
    fromAccount
  ) {
    let amount = getAccountDisplayNumber(toAccount) * -1; // pay off to account in full

    if (
      projection.date === toAccount.next_payment_due_date &&
      toAccount.next_payment_amount
    ) {
      // if not paid in full
      amount = toAccount.next_payment_amount;
    }

    toAccount = updateAccountByAmount(toAccount, amount);
    projection.amount = amount;
    const projectionWithAvailable: ProjectionWithAvailable = {
      ...projection,
      available: getAccountDisplayNumber(toAccount),
    };
    accountProjections.push({
      projectionWithAvailable,
      account: toAccount,
    });

    fromAccount = updateAccountByAmount(fromAccount, -amount);
    projection.amount = -amount;
    const projectionWithAvailableFrom: ProjectionWithAvailable = {
      ...projection,
      available: getAccountDisplayNumber(fromAccount),
    };
    accountProjections.push({
      projectionWithAvailable: projectionWithAvailableFrom,
      account: fromAccount,
    });
    return accountProjections;
  }
  if (fromAccount) {
    fromAccount = updateAccountByAmount(fromAccount, projection.amount);
    const projectionWithAvailable: ProjectionWithAvailable = {
      ...projection,
      available: getAccountDisplayNumber(fromAccount),
    };
    accountProjections.push({
      projectionWithAvailable,
      account: fromAccount,
    });
  }
  return accountProjections;
}

function updateAccountByAmount(account: AccountDocument, amount: number) {
  if (account.type === 'credit' || account.type === 'loan') {
    const available = account.current;
    const newBalance = available + amount || 0;
    account.current = newBalance;
  } else {
    const available = account.available;
    const newBalance = available + amount || 0;
    account.available = newBalance;
  }
  return account;
}

export function getProjectionsForAllAccounts(params: {
  startingBalance: number;
  budgetsById: BudgetsById;
  endDate: string;
}) {
  const { startingBalance, budgetsById, endDate } = params;
  const todaysDate = getTodayString();
  const projections =
    endDate > todaysDate
      ? getProjectedTransactions(
          budgetsById,
          0, // FIXME
          todaysDate,
          endDate
        )
      : [];
  let available = startingBalance || 0;
  return projections
    .filter(
      p =>
        (p.projection_type === 'future' ||
          p.projection_type === 'future-transfer') &&
        p.amount !== 0
    )
    .map(projection => {
      // @todo here we could divide up interest and principle based on account type
      available = available + (projection.amount || 0);
      const projectionWithAvailable: ProjectionWithAvailable = {
        ...projection,
        available,
      };
      return projectionWithAvailable;
    });
}

function addIncomeWeeklyFillProjections(projections: Projection[]) {
  const projectionsByDay: { [day: string]: number } = {};
  projections
    .filter(p => p.projection_type === 'future-fill')
    .map(projection => {
      if (!projectionsByDay[projection.date]) {
        projectionsByDay[projection.date] = 0;
      }
      projectionsByDay[projection.date] -= projection.amount;
    });
  return keys(projectionsByDay).map(day => {
    const incomeProjection: Projection = {
      amount: projectionsByDay[day],
      projection_type: 'future-fill',
      date: day,
      label: translate('WEEKLY_FILL'),
      icon: 'flash',
      color: 7,
      budget_type: 'INCOME',
      budget_id: 'INCOME',
    };

    return incomeProjection;
  });
}

function getWeeklyFillFromBudget(budget: BudgetDocument, dateString: string) {
  const amount = budget.fill_amount;
  const item: Projection = {
    amount,
    date: dateString,
    icon: budget.icon,
    color: budget.color,
    label: translate('WEEKLY_FILL'),
    account_id: null,
    projection_type: 'future-fill',
    budget_id: budget.budget_id,
    budget_type: budget.type,
  };
  return item;
}

function getProjectionFromBudget(budget: BudgetDocument, dateString: string) {
  const amount = budget.type === 'INCOME' ? budget.budget : budget.budget * -1;

  const futureTransfer =
    budget.type === 'BILL' &&
    budget.subtype === 'TRANSFER' &&
    budget.to_account_id
      ? true
      : false;

  const item: Projection = {
    amount,
    date: dateString,
    icon: budget.icon,
    color: budget.color,
    label: budget.name,
    account_id: budget.account_id,
    projection_type: futureTransfer ? 'future-transfer' : 'future',
    budget_id: budget.budget_id,
    budget_type: budget.type,
    to_account_id: budget.to_account_id,
  };
  return item;
}

function getDateStringForProjection(transaction: Projection) {
  const date = getDateAtMidnight(transaction.date);
  const { projection_type } = transaction;
  if (projection_type === 'today' || projection_type === 'past') {
    // past/today transactions can happen at 8AM that day on the graph
    return addHours(date, 8).toISOString();
  } else if (projection_type === 'future-fill') {
    // always show fill happening first - since it happens at midnight
    return date.toISOString();
  } else if (
    projection_type === 'future' ||
    projection_type === 'future-transfer'
  ) {
    // future transactions happen at 12pm to be after today
    return addHours(date, 12).toISOString();
  } else if (projection_type === 'end') {
    // end can happen at end of day on the graph
    return addHours(date, 23.9).toISOString();
  }
  exhaustiveCheck(projection_type);
  return addHours(date, 8).toISOString();
}

export function getGraphDataForBudget(options: {
  budget_id?: string;
  budgets_by_id: BudgetsById;
  past_transactions: TransactionDocument[];
  start: string;
  end: string;
  groupBy?: GroupBy;
  fill_day?: FillDay;
}) {
  const { groupBy = 'day' } = options; // @todo pass param for budget graph too
  if (!options.budgets_by_id) {
    return {
      projections: [],
      datasets: [],
    };
  }
  const past = convertTransactionsToProjections(
    options.past_transactions,
    options.budgets_by_id
  );
  const projectionsByBudgetId = getProjectedTransactionsByBudgetId(
    options.budgets_by_id,
    past,
    options.fill_day as any, // FIXME
    options.start,
    options.end
  );
  if (options.budget_id && projectionsByBudgetId[options.budget_id]) {
    const dataset = getDatasetFromProjections(
      options.budgets_by_id[options.budget_id]?.name,
      options.budgets_by_id[options.budget_id]?.color,
      projectionsByBudgetId[options.budget_id],
      groupBy
    );
    return {
      projections: projectionsByBudgetId[options.budget_id],
      datasets: dataset ? [dataset] : [],
    };
  } else {
    const datasets = keys(projectionsByBudgetId).map(budget_id =>
      getDatasetFromProjections(
        options.budgets_by_id[budget_id]?.name,
        options.budgets_by_id[budget_id]?.color,
        projectionsByBudgetId[budget_id],
        groupBy
      )
    );

    return {
      projections: [], //projectionsByBudgetId[options.budget_id],
      datasets,
    };
  }
}

function getDatasetFromProjections(
  label: string,
  lineColor: number,
  projections: ProjectionWithAvailable[],
  groupBy: GroupBy
) {
  const color = getColor(lineColor, 'primary') || primaryColor;

  if (!projections) {
    const blank: ChartDataset<'line', ScatterDataPoint[]> = {
      data: [],
    };
    return blank;
  }
  const dateMap = getDateMapFromProjections(projections, groupBy);
  const data: DataPoint[] = [];
  const dates = keys(dateMap).sort((a, b) => (a > b ? 1 : -1));
  dates.map(dateString => {
    let available = 0;
    const dateMetadata: DataPoint['metadata'] = [];
    dateMap[dateString].transactions.map(p => {
      available = p.available;
      dateMetadata.push({
        label: p.label,
        value: p.amount,
        color: getColor(p.color, 'primary'),
      });
    });
    if (dateMetadata.length) {
      const pastDataPoint: DataPoint = {
        x: getDateAtMidnight(dateString).getTime(),
        y: available,
        metadata: dateMetadata,
      };
      data.push(pastDataPoint);
    }
  });

  const lineColorNegative = getIonColorRgba('danger', 1);
  // const backgroundColorPastPositive = getColor(lineColor, 'rgba', 0.8);
  const backgroundColorFuturePositive = getColor(lineColor, 'rgba', 0.2);
  // const backgroundColorPastNegative = getIonColorRgba('danger', 0.8);
  const backgroundColorFutureNegative = getIonColorRgba('danger', 0.2);

  const dataset: ChartDataset<'line', ScatterDataPoint[]> = {
    type: 'line',
    label,
    data,
    backgroundColor: color,
    borderColor: color,
    fill: {
      below: backgroundColorFutureNegative,
      above: backgroundColorFuturePositive,
      target: 'origin',
    },
    pointRadius: 0,
    segment: {
      borderColor: ctx => (isNegative(ctx) ? lineColorNegative : color),
      borderWidth: ctx => (isFuture(ctx) ? 1 : 2),
      borderDash: ctx => (isFuture(ctx) ? [2, 2] : undefined),
      // backgroundColor: ctx =>
      //   isFuture(ctx)
      //     ? isNegative(ctx)
      //       ? backgroundColorFutureNegative
      //       : backgroundColorFuturePositive
      //     : isNegative(ctx)
      //     ? backgroundColorPastNegative
      //     : backgroundColorPastPositive,
      // pointRadius: 0,
    },
  };
  return dataset;
}

function getDatasetTypeFromProjections(
  type: BudgetType,
  label: string,
  lineColor: number,
  projections: ProjectionWithAvailable[],
  groupBy: GroupBy
) {
  const color = getColor(lineColor, 'primary') || primaryColor;
  if (!projections) return;

  const dateMap = getDateMapFromProjections(projections, groupBy);

  const data: DataPoint[] = [];
  const dates = keys(dateMap).sort((a, b) => (a > b ? 1 : -1));
  dates.map(dateString => {
    let combinedBalance = 0;
    dateMap[dateString].transactions.map(p => {
      if (p.projection_type !== 'past') return;
      if (p.budget_type === type) {
        if (type === 'INCOME') {
          combinedBalance += p.amount;
        } else {
          combinedBalance -= p.amount;
        }
      }
    });
    const pastDataPoint: DataPoint = {
      x: getDateAtMidnight(dateString)?.getTime(),
      y: combinedBalance,
      metadata: [],
    };
    data.push(pastDataPoint);
  });

  const dataset: ChartDataset<'bar', ScatterDataPoint[]> = {
    type: 'bar',
    label,
    data,
    backgroundColor: color,
    borderColor: color,
    stack: type === 'INCOME' ? 'stack-0' : 'stack-1',
  };
  return dataset;
}

function getDatasetGroupFromProjections(
  budgetIds: string[],
  label: string,
  lineColor: number,
  projections: ProjectionWithAvailable[],
  groupBy: GroupBy
) {
  const color = getColor(lineColor, 'primary') || primaryColor;

  const dateMap = getDateMapFromProjections(projections, groupBy);

  const data: DataPoint[] = [];
  const dates = keys(dateMap).sort((a, b) => (a > b ? 1 : -1));
  dates.map(dateString => {
    let combinedBalance = 0;
    dateMap[dateString].transactions.map(p => {
      if (p.projection_type !== 'past') return;
      if (budgetIds.includes(p.budget_id)) {
        combinedBalance += p.amount;
      }
    });
    const pastDataPoint: DataPoint = {
      x: getDateAtMidnight(dateString)?.getTime(),
      y: combinedBalance,
      metadata: [],
    };
    data.push(pastDataPoint);
  });

  const dataset: ChartDataset<'bar', ScatterDataPoint[]> = {
    type: 'bar',
    label,
    data,
    backgroundColor: color,
    borderColor: color,
    stack:
      // type === 'income' ? 'stack-0' :
      // @todo make budget views work while stacking side by side
      'stack-1',
  };
  return dataset;
}

export function getGraphDataForAccount(params: {
  accountId: string;
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  pastTransactions: TransactionDocument[];
  start: string;
  end: string;
  groupBy: GroupBy;
}) {
  const {
    accountId,
    accountsById,
    budgetsById,
    pastTransactions,
    start,
    end,
    groupBy,
  } = params;
  if (!budgetsById || !accountsById) {
    return {
      projections: [],
      datasets: [],
    };
  }
  const filtered_past = pastTransactions
    .filter(isBankTransaction)
    .sort(sortTransactions);
  const future =
    end > getTodayString()
      ? getProjectedTransactions(
          budgetsById,
          0, // FIXME
          start,
          end
        )
      : [];
  const past = convertTransactionsToProjections(filtered_past, budgetsById);
  const projectionsByAccountId = getProjectedTransactionsByAccountId(
    accountsById,
    past,
    future,
    end
  );
  const projectionsWithAvailable = projectionsByAccountId[accountId];
  const dataset = getDatasetFromProjections(
    getAccountName(accountsById[accountId]),
    accountsById[accountId].color,
    projectionsWithAvailable,
    groupBy
  );
  const datasets = dataset ? [dataset] : [];
  return { datasets, projections: projectionsWithAvailable };
}

export type CashFlowStats = {
  cashFlowStatus:
    | 'getting-out-of-debt'
    | 'going-into-debt'
    | 'going-further-in-debt'
    | 'keeping-debt'
    | 'looking-good';
  weeklyCashFlow: number;
  startingBalance: number;
  outOfMoney: string | null;
  debtFreeDate: string | null;
};
export function getCashFlowStats(params: {
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  excludeBudgetIds: string[];
  todayString: string;
  endDateString: string;
}) {
  const {
    accountsById,
    budgetsById,
    excludeBudgetIds,
    todayString,
    endDateString,
  } = params;
  const accounts = keys(accountsById).map(k => accountsById[k]);
  const { total: startingBalance } = calcTotalAvailable(accounts);
  const budgets = keys(budgetsById)
    .map(k => budgetsById[k])
    .filter(b => !excludeBudgetIds.includes(b.budget_id));
  const budgetsByIdModified: BudgetsById = {};
  for (const b of budgets) {
    budgetsByIdModified[b.budget_id] = b;
  }
  const weeklyCashFlow = budgets.reduce((prev, budget) => {
    const weeklyAmount = calcFrequencyFromBudget({
      budget,
      frequency: 52,
      todayString,
    });
    return prev + (budget.type === 'INCOME' ? weeklyAmount : -weeklyAmount);
  }, 0);

  const projections = getProjectionsForAllAccounts({
    budgetsById: budgetsByIdModified,
    endDate: endDateString,
    startingBalance,
  });

  let debtFreeDate: string | null = null;
  let outOfMoney: string | null = null;
  for (const [i, p] of projections.entries()) {
    const previousEntry = projections[i - 1];
    if (
      !outOfMoney &&
      p.available < 0 &&
      previousEntry &&
      previousEntry.available > 0 &&
      p.date !== previousEntry.date
    ) {
      outOfMoney = p.date;
    }

    if (
      p.available > 0 &&
      previousEntry &&
      previousEntry.available < 0 &&
      p.date !== previousEntry.date
    ) {
      debtFreeDate = p.date;
    }
  }

  let cashFlowStatus: CashFlowStats['cashFlowStatus'] = 'looking-good';
  if (startingBalance > 0) {
    if (weeklyCashFlow < 0) {
      cashFlowStatus = 'going-into-debt';
    } else if (weeklyCashFlow > 0) {
      cashFlowStatus = 'looking-good';
    } else {
      // zen
      cashFlowStatus = 'looking-good';
    }
    // will run out of money
  } else if (startingBalance < 0) {
    // debt free

    if (weeklyCashFlow < 0) {
      cashFlowStatus = 'going-further-in-debt';
    } else if (weeklyCashFlow > 0) {
      cashFlowStatus = 'getting-out-of-debt';
    } else {
      // zen
      cashFlowStatus = 'keeping-debt';
    }
  } else {
  }
  const stats: CashFlowStats = {
    cashFlowStatus,
    weeklyCashFlow,
    startingBalance,
    outOfMoney,
    debtFreeDate,
  };
  return stats;
}

export function getGraphDataForAccounts(params: {
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  end: string;
  groupBy: GroupBy;
  pastTransactions: TransactionDocument[];
  startingBalance: number;
}) {
  const {
    startingBalance,
    budgetsById,
    accountsById,
    pastTransactions,
    end,
    groupBy,
  } = params;
  if (!budgetsById || !accountsById) {
    return {
      projections: [],
      datasets: [],
    };
  }

  const filteredPast = pastTransactions
    .filter(isBankTransaction)
    .sort((a, b) => (a.date > b.date ? -1 : 1));

  const projectionsWithAvailable = getProjectionsForAllAccounts({
    startingBalance,
    budgetsById,
    endDate: end,
  });
  const past = convertTransactionsToProjections(filteredPast, budgetsById);
  const pastWithAvailable = convertPastProjectionsToWithAvailable(
    past,
    startingBalance
  );

  const todayProjection: ProjectionWithAvailable = {
    date: getTodayString(),
    available: startingBalance,
    amount: 0,
    icon: 'flash',
    color: 16,
    label: translate('TODAY'),
    projection_type: 'today',
    budget_id: null as any, // FIXME
    budget_type: null as any, // FIXME
  };
  pastWithAvailable.push(todayProjection);

  const combined: ProjectionWithAvailable[] = [
    ...pastWithAvailable,
    ...projectionsWithAvailable,
  ];

  // all accounts combined
  const dataset = getDatasetFromProjections(
    translate('COMBINED_ACCOUNTS'),
    7,
    combined,
    groupBy
  );
  const datasets: ChartDataset[] = [dataset];
  return { datasets, projections: combined };
}

export function getGraphDataForAccountsByType(params: {
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  pastTransactions: TransactionDocumentWithId[];
  start: string; //@todo remove
  end: string;
  groupBy: GroupBy;
}) {
  const { accountsById, budgetsById, pastTransactions, end, groupBy } = params;
  if (!budgetsById || !accountsById) return;
  const filtered_past = pastTransactions
    .filter(isBankTransaction)
    .sort((a, b) => (a.date > b.date ? -1 : 1));

  const startingBalance = calcTotalAvailable(
    dictionaryToArray(accountsById)
  ).total;
  const projectionsWithAvailable = getProjectionsForAllAccounts({
    startingBalance,
    budgetsById,
    endDate: end,
  });
  const past = convertTransactionsToProjections(filtered_past, budgetsById);
  const pastWithAvailable = convertPastProjectionsToWithAvailable(
    past,
    startingBalance
  );

  const combined: ProjectionWithAvailable[] = [
    ...pastWithAvailable,
    ...projectionsWithAvailable,
  ];

  const sorted = combined.sort((a, b) => (a.date > b.date ? -1 : 1));
  // all accounts combined
  const incomeData = getDatasetTypeFromProjections(
    'INCOME',
    translate('OG_INCOME'),
    7,
    sorted,
    groupBy
  );
  const savingsData = getDatasetTypeFromProjections(
    'SAVINGS',
    translate('SAVING_S', 2),
    budgetTypes.SAVINGS.color,
    sorted,
    groupBy
  );
  const billData = getDatasetTypeFromProjections(
    'BILL',
    translate('BILL_S', 2),
    budgetTypes.BILL.color,
    sorted,
    groupBy
  );
  const spendingData = getDatasetTypeFromProjections(
    'SPENDING',
    translate('SPENDING'),
    budgetTypes.SPENDING.color,
    sorted,
    groupBy
  );
  const cashFlowData = combineData(
    incomeData?.data ?? [],
    savingsData?.data ?? [],
    billData?.data ?? [],
    spendingData?.data ?? []
  );

  const datasets: ChartDataset[] = [
    cashFlowData,
    incomeData,
    savingsData,
    billData,
    spendingData,
  ].filter(isPresent);

  return {
    datasets,
    projections: combined,
  };
}

export function getGraphDataForAccountsByBudgetView(params: {
  accountsById: AccountsById;
  budgetsById: BudgetsById;
  pastTransactions: TransactionDocument[];
  start: string; // @todo remove
  end: string;
  budgetView: BudgetViewDocument;
  groupBy: GroupBy;
}) {
  const {
    accountsById,
    budgetsById,
    pastTransactions,
    end,
    budgetView,
    groupBy,
  } = params;
  if (!budgetsById || !accountsById) {
    const blank: {
      datasets: ChartDataset[];
      projections: [];
      transactionsByDateGroup: [];
    } = {
      datasets: [{ data: [] }],
      projections: [],
      transactionsByDateGroup: [],
    };
    return blank;
  }
  const filtered_past = pastTransactions
    .filter(isBankTransaction)
    .sort((a, b) => (a.date > b.date ? -1 : 1));

  const startingBalance = calcTotalAvailable(
    keys(accountsById).map(k => accountsById[k])
  ).total;
  const projectionsWithAvailable = getProjectionsForAllAccounts({
    startingBalance,
    budgetsById,
    endDate: end,
  });
  const past = convertTransactionsToProjections(filtered_past, budgetsById);
  const pastWithAvailable = convertPastProjectionsToWithAvailable(
    past,
    startingBalance
  );

  const combined: ProjectionWithAvailable[] = [
    ...pastWithAvailable,
    ...projectionsWithAvailable,
  ];

  const sorted = combined.sort((a, b) => (a.date > b.date ? -1 : 1));
  // all accounts combined

  const groupData = budgetView.groups.map(group =>
    getDatasetGroupFromProjections(
      group.budget_ids,
      group.name,
      group.color,
      sorted,
      groupBy
    )
  );
  const cashFlowData = getCashFlowData(groupData.map(g => g.data));

  const datasets: ChartDataset[] =
    groupData.length > 1 ? [cashFlowData, ...groupData] : groupData;
  return {
    datasets,
    projections: combined,
  };
}

function getCashFlowData(dataArray: ScatterDataPoint[][]) {
  const data: DataPoint[] = dataArray[0].map((d, index) => {
    let cashFlow = d.y;
    for (var i = 1; i < dataArray.length; i++) {
      cashFlow += dataArray[i][index].y;
    }
    const point: DataPoint = {
      x: d.x,
      y: cashFlow,
    };
    return point;
  });

  const combinedData: ChartDataset<'line', ScatterDataPoint[]> = {
    type: 'line',
    label: translate('CASH_FLOW'),
    data,
    backgroundColor: getTextColor(),
    borderColor: getTextColor(),
    fill: false,
    pointRadius: 0,
    segment: {
      borderWidth: ctx => (isFuture(ctx) ? 1 : 2),
      borderDash: ctx => (isFuture(ctx) ? [20, 5] : undefined),
    },
  };
  return combinedData;
}

function combineData(
  incomeData: ScatterDataPoint[],
  savingsData: ScatterDataPoint[],
  billsData: ScatterDataPoint[],
  spendingData: ScatterDataPoint[]
) {
  const data: DataPoint[] = incomeData.map((d, index) => {
    const income = d.y;
    const bills = billsData[index].y;
    const savings = savingsData[index].y;
    const spending = spendingData[index].y;
    const combined = income - savings - bills - spending;
    const point: DataPoint = {
      x: d.x,
      y: combined,
    };
    return point;
  });
  const combinedData: ChartDataset<'line', ScatterDataPoint[]> = {
    type: 'line',
    label: translate('PLANNED_CASH_FLOW'),
    data,
    backgroundColor: getTextColor(),
    borderColor: getTextColor(),
    fill: true,
    pointRadius: 0,
    segment: {
      borderWidth: ctx => (isFuture(ctx) ? 1 : 2),
      borderDash: ctx => (isFuture(ctx) ? [20, 5] : undefined),
    },
  };
  return combinedData;
}

function convertTransactionsToProjections(
  past_transactions: TransactionDocument[],
  budgets_by_id: BudgetsById
) {
  const projections: Projection[] = [];
  past_transactions.map(t => {
    t.budgets.map(split => {
      const budget_id = split.child_budget_id ?? split.budget_id;
      if (budget_id === IGNORE_BUDGET_ID) return;
      const budget = budgets_by_id[budget_id];
      if (!budget) return;
      const projection: Projection = {
        amount: split.amount,
        label: t.name_custom ?? t.name,
        color: budget.color,
        icon: budget.icon,
        date: t.date,
        projection_type: 'past',
        account_id: t.account_id,
        budget_id: budget_id,
        budget_type: budget.type,
      };
      projections.push(projection);
    });
  });
  return projections;
}

function convertPastProjectionsToWithAvailable(
  past_transactions: Projection[],
  starting_balance: number
) {
  let balance = starting_balance;
  const reversed = past_transactions
    .map(t => {
      const projectionWithAvailable: ProjectionWithAvailable = {
        ...t,
        available: balance,
      };
      balance -= t.amount;
      return projectionWithAvailable;
    })
    .reverse();
  return reversed;
}

function getDateMapFromProjections(
  projections: ProjectionWithAvailable[],
  groupBy: GroupBy
) {
  const dateMap: {
    [date: string]: { transactions: ProjectionWithAvailable[] };
  } = {};
  projections.map(projection => {
    const dateString = getDateStringForProjection(projection);
    // month
    const groupKey = getGroupByKeyFromDate(groupBy, dateString);
    if (!dateMap[groupKey]) {
      dateMap[groupKey] = { transactions: [] };
    }
    dateMap[groupKey].transactions.push(projection);
  });
  return dateMap;
}

const isNegative = (ctx: ScriptableLineSegmentContext) => {
  const negative = ctx.p0.parsed.y <= 0 && ctx.p1.parsed.y <= 0;
  return negative;
};
